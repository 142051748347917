<template>
  <div class="get-contact">
    <div class="get-contact__title-w">
      <p class="home__subtitle">{{ title }}</p>
      <p class="get-contact__description">
        {{ description }}
      </p>
    </div>
    <ButtonPrimary
      type="button"
      :aria-label="_T('@More details')"
      button-width="236px"
      @click.prevent="
        modalStore.toggleModal(getContactModal, {
          title,
          description,
        })
      "
    >
      {{ _T("@More details") }}
    </ButtonPrimary>
  </div>
</template>

<script setup>
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import {
  getContactModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";

const props = defineProps({
  title: { type: String, required: true },
  description: { type: String, required: true },
});

const modalStore = useModalStore();
</script>

<style scoped lang="scss">
.get-contact {
  max-width: 940px;
  width: 100%;

  @include flex-container(row, space-between, center);
  flex-wrap: wrap;
  gap: 16px;

  @include bigMobile {
    flex-direction: column;
  }

  &__title-w {
    @include flex-container(column, center, flex-start);
    gap: 16px;

    @include bigMobile {
      align-items: center;
      text-align: center;
    }
  }
}

.home__subtitle {
  @include bigMobile {
    align-self: center;
  }
}
</style>
