<template>
  <div class="promotion-card">
    <MyLink name="category" :params="{ categorySlug: promotionCategorySlug }">
      <img src="~~/assets/images/carousel-sales-link.png" alt="promotion" />
    </MyLink>
    <div class="promotion-card__footer">
      <div class="promotion-card__text-block">
        <p class="promotion-card__title">
          {{ _T("@Home promotion card title") }}
        </p>
        <p class="promotion-card__cation">
          {{ _T("@Home promotion card caption") }}
        </p>
      </div>
      <MyLink
        class="button-primary"
        name="category"
        :params="{ categorySlug: promotionCategorySlug }"
      >
        {{ _T("@Promotions") }}
      </MyLink>
    </div>
  </div>
  <MyLink
    class="button-primary promotion-card__mobile"
    name="category"
    :params="{ categorySlug: promotionCategorySlug }"
  >
    {{ _T("@All promotions") }}
  </MyLink>
</template>

<script setup>
import { useCategoriesStore } from "~/store/categoriesStore";
import { promotionCategoryCode } from "~/config/category.config";

const categoriesStore = useCategoriesStore();
const promotionCategorySlug = categoriesStore.getCategoryByCode(
  promotionCategoryCode,
).slug;
</script>

<style scoped lang="scss">
.promotion-card {
  max-width: 226px;
  width: 100%;

  @include flex-container(column, null);

  @include bigMobile {
    display: none;
  }

  &__footer {
    height: 100%;

    @include flex-container(column, space-between, center);
    gap: 8px;

    border-radius: 16px;
    background-color: var(--color-blue-100);

    padding: 16px;
  }

  &__text-block {
    @include flex-container(column, center, center);
    gap: 2px;

    text-align: center;
  }

  &__title {
    @include font(18, 24, 700);
    text-transform: uppercase;
    color: var(--color-primary-base);
  }

  &__caption {
    @include font(14, 20, 500);
  }

  &__mobile {
    display: none;

    @include bigMobile {
      display: flex;
    }

    &.button-primary {
      width: min(210px, 100%);
      align-self: center;
    }
  }
}
</style>
