<template>
  <div
    v-if="getProducts.status === 'success' && 0 !== getProducts.data.length"
    class="promotion-wrapper"
  >
    <div class="promotion-wrapper__container">
      <p class="home__subtitle">
        {{ _T("@Promotion slider title") }}
      </p>
      <div class="promotion-wrapper__carousel">
        <ProductCarousel :products="getFilteredProducts(getProducts.data)" />
        <PromotionCarouselCard />
      </div>
    </div>
  </div>
</template>

<script setup>
import PromotionCarouselCard from "~/modules/home/ui/components/PromotionCarouselCard.vue";
import ProductCarousel from "~/modules/shared/sliders/ProductCarousel.vue";
import { getProductsByLabel } from "~/utils/getters.utils";
import { Product } from "~/models/product.model";

const getProducts = await getProductsByLabel("promotion");

const getFilteredProducts = (products) => {
  return products
    .filter((product) => product.isOrderable)
    .map((product) => new Product(product));
};
</script>

<style lang="scss">
.promotion-wrapper {
  background-color: var(--color-green-100);

  @include flex-container(row, center, center);

  padding: 16px;

  &__container {
    @extend %width-main;

    @include flex-container(column, flex-start);
    gap: 16px;
  }

  &__carousel {
    @include flex-container(row, flex-start);
    gap: 8px;

    @include bigMobile {
      @include flex-container(column, center);
      gap: 16px;
    }
  }
}
</style>
