<template>
  <div class="home">
    <BannerSlider
      v-if="0 !== contentfulStore.homeSlider.length"
      :slides="contentfulStore.homeSlider"
    />
    <RecommendedCategories :categories="getRecommendedCategories()" />
    <div class="home__showcase">
      <ProductShowcase :tabs="labelCarouselTabs" />
    </div>
    <PromotionCarouselWrapper />
    <GetContactWrapper
      :title="_T('@CTA title SES')"
      :description="_T('@CTA text SES')"
    >
      <template #image>
        <img
          src="~~/assets/images/right-watch-cat.png"
          alt="right-watch-cat"
          style="height: 100%"
        />
      </template>
    </GetContactWrapper>
    <div class="home__showcase">
      <ProductShowcase :tabs="getRecommendedCategories(6)" with-scroll>
        <h5 class="home__subtitle">{{ _T("@Featured") }}</h5>
      </ProductShowcase>
    </div>
    <GetContactWrapper
      wide-view
      :title="_T('@CTA title heating')"
      :description="_T('@CTA text heating')"
    >
      <template #image>
        <img
          src="~~/assets/images/cat-in-hat.png"
          alt="cat-in-hat"
          style="height: 100%"
        />
      </template>
    </GetContactWrapper>
    <div class="home__container">
      <InstallationSimilarPreview>
        <h5 class="home__subtitle">{{ _T("@Our another objects") }}</h5>
      </InstallationSimilarPreview>
    </div>
    <div class="home__container">
      <BlogSimilarPreview>
        <h5 class="home__subtitle">{{ _T("@Read also") }}</h5>
      </BlogSimilarPreview>
    </div>
    <div class="home__container">
      <FaqList :faq-list="faqList">
        <template #title>
          <h2 class="home__subtitle colored">{{ _T("@FAQ") }}</h2>
        </template>
        <template #button>
          <MyLink
            class="button-primary"
            name="faq"
            :params="{ faqSlug: 'popular-questions' }"
          >
            {{ _T("@All questions") }}
          </MyLink>
        </template>
      </FaqList>
    </div>
    <!--    <Description />-->
    <MyLink class="button-primary" name="catalog">
      <img src="~~/assets/icons/catalog.svg" :alt="_T('@Goods catalog')" />
      <span>{{ _T("@Goods catalog") }} </span>
    </MyLink>
  </div>
</template>

<script setup>
import { useContentfulStore } from "~/store/contentfulStore";
import BannerSlider from "~/modules/shared/sliders/bannerSlider/BannerSlider.vue";
import ProductShowcase from "~/modules/shared/productShowcase/ProductShowcase.vue";
import PromotionCarouselWrapper from "~/modules/home/ui/components/PromotionCarouselWrapper.vue";
import FaqList from "~/modules/shared/faq/FaqList.vue";
import Description from "~/modules/home/ui/components/Description.vue";
import GetContactWrapper from "~/modules/home/ui/components/GetContactWrapper.vue";
import BlogSimilarPreview from "~/modules/blog/components/BlogSimilarPreview.vue";
import InstallationSimilarPreview from "~/modules/installationWork/components/InstallationSimilarPreview.vue";
import { useCategoriesStore } from "~/store/categoriesStore";
import RecommendedCategories from "~/modules/home/ui/components/RecommendedCategories.vue";
import logoImg from "assets/icons/LogicPower-Logo.png";
import { useAsyncPrivateAPI } from "~/uses/useMyFetch";

//TODO Описание жду переменные

const config = useRuntimeConfig();
const route = useRoute();

const categoriesStore = useCategoriesStore();
const { getCategoriesByLabel } = storeToRefs(categoriesStore);

const contentfulStore = useContentfulStore();
await contentfulStore.fetchHomeSlider();

const labelCarouselTabs = [
  {
    title: "@Novelties",
    component: defineAsyncComponent(
      () =>
        import(
          "~/modules/shared/sliders/productCarousels/ProductCarouselByLabel.vue"
        ),
    ),
    label: "novelty",
  },
  {
    title: "@Popular",
    component: defineAsyncComponent(
      () =>
        import(
          "~/modules/shared/sliders/productCarousels/ProductCarouselByLabel.vue"
        ),
    ),
    label: "bestSeller",
  },
];

const { data: faqList } = await useAsyncPrivateAPI("/entries", {
  params: {
    content_type: useRuntimeConfig().public.contentful.contentType.faqList,
    "fields.headings.sys.id": useRuntimeConfig().public.contentful.homeFaqId,
  },
});

const getRecommendedCategories = (size = undefined) => {
  return getCategoriesByLabel
    .value("featured")
    .slice(0, size ?? getCategoriesByLabel.value("featured").length)
    .map((category) => {
      return {
        title: category.getName(),
        category,
        component: defineAsyncComponent(
          () =>
            import(
              "~/modules/home/ui/components/RecommendedCategoryProducts.vue"
            ),
        ),
      };
    });
};

useSeoMeta({
  title: _T("@Meta title"),
  description: _T("@Meta description"),
  ogUrl: config.public.appUrl + route.path,
  ogImage: logoImg,
});
</script>

<style lang="scss">
.home {
  @include flex-container(column, flex-start);
  gap: 32px;

  padding-block: 40px;

  &__container {
    @include flex-container(row, center, center);
    padding-inline: 16px;
  }

  &__showcase {
    padding-inline: 16px;
  }

  &__subtitle {
    @include font(26, 32, 600);
    align-self: flex-start;

    @include mobile {
      @include font(18, 24, 600);
    }

    &.colored {
      color: var(--color-primary-base);
    }
  }
}
</style>

<style scoped lang="scss">
.button-primary {
  width: min(265px, 100%);
  align-self: center;
}
</style>
